@import '../../styles/customMediaQueries.css';

.terms_use__page {
  & #terms-of-service-banner {
    & .SectionContainer_sectionContent__vqzb1 {
      padding: 75px 0;
    }
    & .Heading_h1__8evh7 {
      font-size: 32px;
      position: relative;
      &::before {
        content: 'Terms';
        position: absolute;
        left: 0;
        top: 0;
        color: var(--attentionColor);
      }
    }
    & .P_p__qMuDQ {
      margin: 20px 0 0;
      line-height: 1.25;
      color: var(--matterColorLight);
    }
    & .SectionBuilder_blockContainer__M5X1Y {
      grid-template-columns: 100%;
      padding: 0 16px;
      @media (--viewportLargeIpad) {
          grid-template-columns: repeat(2, calc((100% - 32px) / 2.9));
      }
      & .BlockDefault_text__uquKZ {
        max-width: 100%;
      }
    }

    @media (--viewportLargeIpad) {
      & .SectionContainer_sectionContent__vqzb1 {
        padding: 115px 0;
      }
      & .Heading_h1__8evh7 {
        font-size: 40px;
      }
    }
  }
  & #terms-content {
    & #general-terms{
      padding-top: 0px;
    }
    & #sub-title {
      padding-top: 0;
      & p {
        font-weight: 600;
      }
    }
    & .SectionBuilder_blockContainer__M5X1Y {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0;
      padding: 0 16px;
      & .BlockContainer_root__9JaaU {
        &:first-child {
          display: none;
        }
        &:not(:first-child) {
          padding-top: 32px;
        }
        & .Heading_h3__U-efn {
          font-size: 16px;
        }
        & .P_p__qMuDQ {
          max-width: 100%;
          font-size: 14px;
          line-height: 19px;
        }
      }
      & .List_ul__SLzBr {
        padding: 0;
      }
      & .List_li__u1CDk {
        line-height: 1;
        list-style: none;
        margin-bottom: 12px;
        & .List_ul__SLzBr{
          padding-left: 0px;
          margin-top: 10px;
          & .List_li__u1CDk{
            & a{
              font-size: 15px;
              line-height: 18px;
              text-decoration: none;
              font-weight: 100;
            }
          }
        }
        & a {
          color: var(--matterColorDark);
          font-size: 19px;
          line-height: 29px;
          font-weight: 600;
          text-decoration: none;
        }
      }

      @media (--viewportLargeIpad) {
        padding-left: 330px;
        &::before {
          content: '';
          position: absolute;
          left: 270px;
          top: -64px;
          width: 1px;
          background-color: #ddd;
          bottom: -64px;
        }
        & .BlockContainer_root__9JaaU {
          &:first-child {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 310px;
            padding-left: 16px;
            height: 100%;
            & .BlockDefault_text__uquKZ {
              position: sticky;
              top: 100px;
            }
          }
          & .Heading_h3__U-efn {
            font-size: 18px;
          }
          & .P_p__qMuDQ {
            max-width: 90ch;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
